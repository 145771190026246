.product-images{
    text-align: center;
    margin-top: 30px;
}

.WirelessLogoP{
    width: 150px;
    height: 70px; 
      
}
.ICCountLogoP{
    width: 150px;
    height: 70px;
    margin-left: 30px;
}
.CreditCardLogoP{
    width: 100px;
    height: 100px;
    margin-left: 30px;
}
.TBLogoP{
    width: 150px;
    height: 150px;
    margin-left: 30px;
}
.PDFTOEXCELLogoP{
    width: 150px;
    height: 70px;
    margin-left: 30px;
}
.QuickBaseLogoP{
    width: 120px;
    height: 100px;
    margin-left: 30px;
}
.LicenseLogoP{
    width: 150px;
    height: 90px;
    margin-left: 30px;
}

@media only screen and (max-width: 1008px){
    .product-images{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-row-gap: 20px;
    }
    .WirelessLogoP{
        width: 80px;
        height: 50px;
    }
    .ICCountLogoP{
        width: 80px;
        height: 80px;
    }
    .CreditCardLogoP{
        width: 80px;
        height: 80px;
    }
    .TBLogoP{
        width: 80px;
        height: 80px;
    }
    .PDFTOEXCELLogoP{
        width: 80px;
        height: 80px;
    }
    .QuickBaseLogoP{
        width: 80px;
        height: 80px;
    }
    .LicenseLogoP{
        width: 80px;
        height: 80px;
    }
}
@keyframes example {
    from {background-color: red;}
    to {background-color: yellow;}
  }
