
.app {
  font-family: Quicksand, sans-serif;
  margin: 0px;
  padding: 0px;
}
.header{
    margin: 0px;
    border: none;
    display: flex;
    justify-content: space-between;
}

.right-div button{
    margin-top: 20px;
    margin-right: 20px;
    border-radius: 20px;
}
.login-btn{
    width: 95px;
}
.header img{
    height: 80px;
    width: 170px;
    position: relative;
    border-radius: 100px;
   } 
   .sponsor-img-div{
    text-align: center !important;
    margin-left: auto;
    margin-right: auto;
}
   .Sponsor-img{
    width: 200px !important;
    height: 50px !important;
    margin-top: 15px;
    border-radius: 0px !important;
   }
   
.navigation{
    display: flex;
    height: 60px;
    align-items: center;
    background-color: #11abed;

}

.navigation>li{ 
 font-size: 13px;
  align-content: center;
  border-radius: 10px;
  margin-right: 40px;
  padding: 10px;
}

.navigation>li a{
    color: black;
    letter-spacing: 4px;
    font-weight:700;
    font-style: oblique; 
    justify-content: space-between;
}
.navigation>li>a .dropdown{
    visibility: hidden;
    position: absolute;
    margin-top: 10px;
    width: 50%;
    z-index: 120000000;
    box-shadow: 0 70px 63px -60px #4f0808;
    background:#f8f8f8;
    border-radius: 10px;
    -webkit-transition:opacity 0.2s linear, visibility 0.2s linear;
	-moz-transition:opacity 0.2s linear, visibility 0.2s linear;
	-o-transition:opacity 0.2s linear, visibility 0.2s linear;
	transition:opacity 0.2s linear, visibility 0.2s linear;
    display: flex; 
    flex-direction: row;
    padding-left: 40px;
    flex-wrap: wrap;
    justify-content: space-between;
    justify-items: center;
}
.navigation>li>a>.dropdown li{
  list-style: circle;
  padding: 10px;
  width: 40%;
}
.navigation>li>a>ol>li{
    width: 80%;
    padding: 10px;
}
.navigation>li>a ol>li a{
    color: rgb(25, 27, 27);
    letter-spacing: 2px;
    font-size: 12px;
}

.navigation>li>a:hover > .dropdown{
    opacity: 100%; 
    visibility: visible;
    background-color: rgb(244, 244, 244);
    color: rgb(16, 193, 247);
}
.navigation>li a:hover{
    color: rgb(213, 213, 213);
}

.back{
    width: 20%;
    margin: 20px;
    color: #70b741;


}
.back:hover{
    color: #0d1c03;
    cursor: pointer;
}

.footer{
    background-color: #33804a;
    padding-top: 10px;
    padding-bottom: 0px;
    position:fixed;
    bottom:0;
    width:100%;
    height: 60px;
}

  /* mobile responsiveness Download and Login button */
  @media only screen and (max-width: 1008px) {
 
    .Sponsor-img{
        width: 100px !important;
         height: 30px !important;
         margin-top: 50px;


    }
  .navigation{
 width: 100%;
 margin: 0px;
 padding: 12px;
}
.navigation>li{
 margin: 2%;
padding: 0px;
font-size: 10px;
}
.navigation>li>a .dropdown{
    width: 90% !important;
    font-size: 10px;
   } 
.navigation>li>a>.dropdown li{
    list-style: circle;
    padding: 5px;
    width: 50%;
  }
.header{
    margin: 1px;
    justify-content:flex-start;    
}
.right-div{
    margin-left: auto;
    margin-bottom: 5px;
    
}
.right-div button{
    display: block;
    font-size: 12px;
    margin-left: auto;
    margin-right: auto;
}
.back{
    width: 40%;
    font-size: 12px;
}
}
@media only screen and (max-width:700px) {
   
    .Sponsor-img{
        width: 80px !important;
        height: 25px !important;
        margin-top: 30px;
        border-radius: 0px !important;
       }
    .navigation{
        width: 100%;
        margin: 0px;
        padding: 10px;
       }
       .navigation>li{
        font-size: px;
       }
       .navigation>li>a .dropdown{
        width: 80%;
        
       } 
       .navigation>li>a>.dropdown li{
        list-style: circle;
        padding: 0px;
        font-size: 20px;
        width: 50%; 
      }
      .navigation>li>a ol>li a{
        color: rgb(25, 27, 27);
        letter-spacing: 0px;
        font-size: 9px;
           
    }
    
}