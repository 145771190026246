.pricing_container{
    margin-right: 40px;
    margin-left: 40px;
}
.pricing-heading{
   margin-left: 40px;
}
.pricing-table{
    border-collapse: collapse;
    width: 100%;
  } 
  .pricing-text{
    margin-right: 40px;
    margin-left: 40px;
  }
  .pricing-th {
    border: none;
    text-align: left;
    padding: 1em;
  }
  .pricing-td {
    border: none;
    text-align: left;
    padding: 1em;
  }
  @media only screen and (max-width: 760px){
    .pricing_container{
      margin-right: 5px;
      margin-left: 5px;
     overflow-x: scroll;   
    }   
    .pricing-table{
      border-collapse: collapse;
      width: auto;
      
    }
::-webkit-scrollbar {
  width: fit-content;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #70b741; 
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  background: rgb(16, 193, 247); 
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
  background: #70b741; 
}.pricing-table{
  width: 100%;
}
.pricing-th {
  border: none;
  text-align: left;
  padding: 0.5em;
  
}
.pricing-td {
  border: none;
  text-align: left;
  padding: 0.5em;
  width: fit-content;
}

    .pricing-text{
      font-size: 10px;
      margin-right: 5px;
      margin-left: 5px;
    }
  }

 
  