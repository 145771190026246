.download_container{
    margin-right: 80px;
    margin-left: 80px;
}
.download-table{
    border-collapse: collapse;
    width: 100%;
  }
  
  .download-th {
    border: none;
    text-align: left;
    padding: 1em;
  }
  .download-td {
    border: none;
    text-align: left;
    padding: 1em;
  }
/* Modal Css start */
.modal{
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: linear-gradient(to bottom right, rgb(220, 190, 190), yellow);
  border-radius: 4px;
  width: 40vh;
  justify-content: center;
  z-index: 10;
}
.modal input{
  margin: 4px;
  width: 240px;
  padding: 10px;
  border-radius: 6px;
}

.modal .btn-primary {
  background: #4b5779;
  color: #ffffff;
}

  .btn {
    appearance: none;
      -webkit-appearance: none;
    cursor: pointer;
    font-weight: 500;
    width: 200px;
    margin-top: 10px;
    height: 40px;
    border-radius: 10px;
 
    text-align: center;
    border: 0px;
      -webkit-transition: background-color 100ms linear;
      -ms-transition: background-color 100ms linear;
       transition: background-color 100ms linear;
  }

.modal .btn-primary:hover {
  background: #2980b9;
  color: #ffffff;
}
/* Modal Css End */

@media only screen and (max-width: 824px){
  .download_container{
    margin-right: 5px;
    margin-left: 5px;
  }
  .download-table{
    width: 100%;
    font-size: .8em;
  }
  .download-th{
    padding: 1em;
  }
  .download-td{
    padding: 1em;
  }
}
@media only screen and (max-width: 495px){
 .download_container{
  margin-right: 5px;
  margin-left: 5px;
 }
  .download-table{
    font-size: .8em;
  }
  .download-th{
    padding: 0.3em;
  }
  .download-td{
    padding: 0.3em;
  }
}

 
  