
.solution_container{
    width: auto;
    height: 90vh;
    margin-right: 16px;
    margin-left: 16px;
    margin-top: 20px;
    margin-bottom: 30px;
    transition: height 2s; 
    transition: width 2s, height 4s;
}

.images img{
    width: 600px;
    height: 400px;
    opacity: 0.9;
    float: left;
    margin: 10px;
    border: 1px solid rgb(51, 153, 225); 
    border-radius: 10px;
}

.description h1{
    color: rgb(51, 153, 225);
    font-size: 36px;
    font-weight: bolder;  
    text-align: center;
    letter-spacing: 4px;
    width: 400px;
    float: left;
}
.paragraph {
    right: 0;
    font-size: 18px;
    letter-spacing: 1px;
    text-decoration: wavy;
    line-height: 40px;
    align-content: space-between;
    opacity: 80;
    transition: linear 1s;
}
.ant-modal-content,.ant-modal{
  padding: 0px;
  width: 100%;
  height: 380px;
  border-radius: 20px;
  background-image:linear-gradient(rgb(51, 153, 225), yellow);
}
.ant-modal-header,.ant-modal-footer{
    visibility: hidden;
}

.modal-body>.modal-badge {
 display: flex;
 justify-content: flex-end;
}
.modal-body>.modal-badge img{
    z-index: 20;
    height: 20%;
    width: 20%;
}
.modal-btn{
    display: flex;
    justify-content: space-between;
    width: 50%;
}
.license-modal{
    list-style:square;

}