.col-1{
    height: 60vh; 
    margin-left: 30px;
    padding-top: 30px;
    margin-right: 16px;
}

.about-paragraph{
    font-size: 18px;
    transition: linear 1s;
    text-align: justify;
    letter-spacing: 4px;
    word-spacing: 4px;
    line-height: 60px;
}
.contact{
    margin-top: 64px;
    height: 200px;
    width: 50%;
    margin-bottom: 20px;
    float: right;
    font-size: 16px;
}
.about_img{
    float: right;
    width: 40%;
    height: 10%;
}

.contact_img{
    float: left;
    width: 30%;
    height: auto;
}

@media only screen and (max-width: 900px){
   .about_img{
        float: right;
        width: 40%;
        height:10%;
    }
    .about-heading{
     font-size: 120px;
     
    }
    .about-paragraph{
        font-size: 12px;
        letter-spacing: 2px;
        word-spacing: 6px;
        line-height: 30px;
    }
    .contact{
        margin: 0px;
        margin-top: 44px;
        margin-right: 10px;
        padding: 0px;
        width: 50%;
        height: 200px;
        font-size: 14px;
        margin-bottom: 20px;
    } 
}

 @media only screen and (max-width: 600px){
    .about_img{
         float: right;
         width: 50%;
         height: 10%;
     }
     .contact_img{
        width: 40%;
        height:20%;
        margin-left: 50%;
    } 
    .contact{
        margin: 0px;
        margin-left: auto;
        margin-right: auto;
        float: left;
        width: 100%;
        height: 200px;
        font-size: 12px;
    }
 }