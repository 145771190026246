.LandingPage-images {
  margin-top: 30px;
  display: flex;
  width: 80%;
  overflow-x: hidden;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 10px;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  text-align: center;
}
.LandingPage-images>div {
    width: 300px;
    border-radius: 20px;
    height: 250px;
    margin: 10px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    display: flex;
    padding-left: 2%;
    flex-direction: column;
  }
  .LandingPage-images>div img {
    width: 80%;
    height: 160px;
  }