.contact-container{
    margin-right: 40px;
    margin-left: 40px;
    width: fit-content;
    overflow:hidden;

}
.contact_title{
    margin-top: 30px;
    font-weight: bold;
    letter-spacing: 6px;
}
.contact-list-div{
    text-align: left;
    background-color: #70b741;
}
.contact-div{
    width: 80%;
    display: flex;
    margin-left: 18%;
}
.contact_paragraph{
    font-size: 16px;
    letter-spacing: 3px;
    line-height: 40px;
}
.contact-us-list{
    float: left;
}
.contact_form {
   width: 525px;
   padding: 10px;
   
}
.form_heading{
    width: 300px;
    font-size: 29px;
    color: rgb(51, 153, 225);
}
label{
    font-size: 16px;
    color: rgb(51, 153, 225);
}
.input_field{
    width: 60%;
    padding: 12px 20px;
    margin: 8px 0;
    border: 1px solid transparent;
    border-radius: 5px;
    background-color: rgba(231, 231, 222, 0.914);
}
textarea{
    width: 60%;
    height: 150px;
    padding: 12px 20px;
    margin: 8px 0;
    background-color: rgba(231, 231, 222, 0.914);
    border: 1px solid transparent;
    border-radius: 5px;
}
.submit_reset_button{
        border: none;
        padding: 16px 32px;
        width: 150px;
        text-decoration: none;
        margin: 4px 6px;
        cursor: pointer;
        background-color: #11abed;
        color: white;
        font-size: 12px;
        border-radius: 50px;
}
.submit_reset_button:hover{
    background-color:#70b741;;
    transition: 0.2s;
}
.ags_info{
    color: rgb(51, 153, 225);
    font-size: 29px;
    font-weight: bolder;   
}
ul li{
    list-style: none;
}
.ags_info_icons{
    margin-right: 20px;
}

.contactimg{
    width: 420px;
    height: 300px;
    margin-left: auto;
    margin-right: auto;
    float: left;
}
.contact-list{
    margin-top: 50px;
    width: 340px;
}


/* mobile responsiveness contact us */
@media only screen and (max-width: 950px) {
    .contact-div{
        width: auto;
        display: flex;
        flex-direction: column;
    }
    .contact-container{ 
        display: flex;
        flex-direction: column; 
        margin-right: 20px;
        margin-left: 20px;
        width: fit-content;
    }
    .contact-us-list{
        float: none;
        align-items: center;
    }
    .contact_form {
       width: fit-content; 
       align-items: center;
       float: none;
       display: flex;
       justify-content: center;
       padding: 0px;
    }

     .form_heading{
         font-size: 28px;
         color: rgb(51, 153, 225);
     }
     .input_field{
         width: 350px;
     }
     textarea{
        width: 350px;
     }
     .contact_title{
        margin-left: 20px;
        margin-right: 20px;
     }
     .contact_paragraph{
        font-size: 1em;
        letter-spacing: 2px;
         line-height: 40px;
         margin-left: 20px;
         margin-right: 20px;
}
.submit_reset_button{
    border: none;
    padding: 16px 32px;
    width: 120px;
    text-decoration: none;
    margin: 4px 6px;
    cursor: pointer;
    background-color: #11abed;
    color: white;
    font-size: 8px;
    border-radius: 5px;
}
.contactimg{
    margin-left: 0px;
    margin-right: 0px;
    width: 350px;
}
}

@media only screen and (max-width: 500px){
   .contact-container{
    width: fit-content;
    margin-right: 10px;
    margin-left: 10px;
   }
   .contact-div{
    width: fit-content;
    margin-right: auto;
    margin-left: auto;
   }
   .input_field{
    width: 300px;
}
textarea{
   width: 300px;
}
.contactimg{
    width: 330px;
    height: 300px;
    float: left;
    margin-right: auto;
    margin-left: auto;
}
.contact-list{
    float: none;
    align-items: center;
    padding: 0px;
    width: fit-content;
}
.contact-form-div{
    width: fit-content;

    align-items: center;
    justify-content: center;
}
.contact_form {
    width: fit-content; 
     display: flex;      
     padding: 0px;
 }
 .contact_paragraph{
        font-size: .8em;
        letter-spacing: 2px;
         line-height: 40px;
         margin-right: 0px;
         margin-left: 0px;
 }
}